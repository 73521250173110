import { Injectable } from '@angular/core';
import {
  Project,
  ProjectsService,
  Workload,
  WorkloadsService
} from '@cloud-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import * as ProjectActions from './project-store.actions';
import { getProject, selectWorkload } from './project-store.selectors';
import { ProjectStoreState } from './project-store.state';

@Injectable({
  providedIn: 'root',
})
export class ProjectStoreEffects {

  constructor(
    private _actions$: Actions,
    private _projectService: ProjectsService,
    private _store: Store<ProjectStoreState>,
    private _workloadsService: WorkloadsService,
  ) {}

  processParams = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((data: any) => data.payload.routerState.params['projectid']),
      withLatestFrom(this._store.select(getProject)),
      filter(
        ([project_id, project]) =>
          (project_id != undefined || project != undefined) &&
          project_id != project?.id,
      ),
      map(([project_id, project]) => {
        const current_id = (project as Project)?.id;
        if (
          project_id != null &&
          project_id != undefined &&
          project_id !== current_id
        ) {
          return ProjectActions.loadProject({ project_id });
        } else {
          return ProjectActions.loadProjectSuccess({ project: undefined });
        }
      }),
    ),
  );

  setCurrentWorkload = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((data: any) => [
        data.payload.routerState.params['projectid'],
        data.payload.routerState.params['workloadid'],
      ]),
      withLatestFrom(this._store.select(selectWorkload)),
      filter(
        ([[project_id, workload_id], workload]) =>
          (workload_id != undefined || workload != undefined) &&
          workload_id != workload?.id,
      ),
      map(([[project_id, workload_id], workload]) => {
        const current_id = (workload as Workload)?.id;
        if (
          workload_id != null &&
          workload_id != undefined &&
          workload_id !== current_id
        ) {
          return ProjectActions.loadWorkload({ project_id, workload_id });
        } else {
          return ProjectActions.loadWorkloadSuccess({ workload: undefined });
        }
      }),
    ),
  );

  loadProject$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectActions.loadProject),
      concatMap(({ project_id }) =>
        this._projectService.apiProjectsIdGet(project_id).pipe(
          map((project) => ProjectActions.loadProjectSuccess({ project })),
          catchError((error) =>
            of(ProjectActions.loadProjectFailure({ error })),
          ),
        ),
      ),
    ),
  );

  onLoadProjectSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ProjectActions.loadProjectSuccess),
      ),
    { dispatch: false }, // Set dispatch to false because we don't dispatch any action here
  );

  loadWorkload$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectActions.loadWorkload),
      concatMap(({ project_id, workload_id }) =>
        this._workloadsService.getWorkload(project_id, workload_id).pipe(
          map((workload) => ProjectActions.loadWorkloadSuccess({ workload })),
          catchError((error) =>
            of(ProjectActions.loadWorkloadFailure({ error })),
          ),
        ),
      ),
    ),
  );

  loadWorkloadFiles$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectActions.loadWorkloadSuccess),
      filter(({ workload }) => workload != undefined),
      withLatestFrom(this._store.select(getProject)),
      filter(([{ workload }, project]) => project != undefined),
      concatMap(([{ workload }, project]) =>
        this._workloadsService
          .getWorkloadFileList(project?.id!, workload?.id!)
          .pipe(
            map((files) => ProjectActions.loadWorkloadFilesSuccess({ files })),
            catchError((error) =>
              of(ProjectActions.loadWorkloadFilesFailure({ error })),
            ),
          ),
      ),
    ),
  );

  reloadWorkloadFiles$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectActions.reloadWorkloadFiles),
      switchMap(() =>
        combineLatest([
          this._store.select(getProject),
          this._store.select(selectWorkload),
        ]),
      ),
      filter(
        ([project, workload]) => project != undefined && workload != undefined,
      ),
      concatMap(([project, workload]) =>
        this._workloadsService
          .getWorkloadFileList(project?.id!, workload?.id!)
          .pipe(
            map((files) => ProjectActions.loadWorkloadFilesSuccess({ files })),
            catchError((error) =>
              of(ProjectActions.loadWorkloadFilesFailure({ error })),
            ),
          ),
      ),
    ),
  );

  reloadProject$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectActions.reloadProject),
      withLatestFrom(this._store.select(getProject)),
      map(([t, project]) => {
        if (project) {
          return ProjectActions.loadProject({ project_id: project.id! });
        } else {
          return ProjectActions.loadProjectSuccess({ project: undefined });
        }
      }),
    ),
  );

  saveProjectOptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProjectActions.saveProjectOptions),
      map((action) => action.projectOptions),
      withLatestFrom(this._store.select(getProject)),
      filter(([projectOptions, project]) => project != undefined),
      concatMap(([projectOptions, project]) =>
        this._projectService
          .putProjectOptions(project?.id!, projectOptions)
          .pipe(
            map(() => ProjectActions.reloadProject()),
            catchError((error) =>
              of(ProjectActions.loadProjectFailure({ error })),
            ),
          ),
      ),
    ),
  );
}
