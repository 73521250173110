import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { SettingsService } from '@core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-user',
  template: `
    @if (auth.user$ | async; as user) {
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <img class="avatar" [src]="user.picture" width="24" alt="avatar" />
      </button>
    }

    <mat-menu #menu="matMenu">
      <!-- <button routerLink="/profile/overview" mat-menu-item>
        <mat-icon>account_circle</mat-icon>
        <span>{{ 'profile' | translate }}</span>
      </button>
      <button routerLink="/profile/settings" mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>{{ 'edit_profile' | translate }}</span>
      </button>
      <button mat-menu-item (click)="restore()">
        <mat-icon>restore</mat-icon>
        <span>{{ 'restore_defaults' | translate }}</span>
      </button> -->
      <button routerLink="/" mat-menu-item>
        <mat-icon>view_list</mat-icon>
        <span>{{ 'projects' | translate }}</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'logout' | translate }}</span>
      </button>
    </mat-menu>
  `,
  styles: `
    .avatar {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50rem;
    }
  `,
  standalone: true,
  imports: [AsyncPipe, RouterLink, MatButtonModule, MatIconModule, MatMenuModule, TranslateModule],
})
export class UserComponent {
  public readonly auth = inject(AuthService);
  public readonly router = inject(Router);

  private readonly settings = inject(SettingsService);

  logout() {
    this.auth.logout({ logoutParams: { returnTo: document.location.origin } });
  }

  restore() {
    this.settings.reset();
    window.location.reload();
  }
}
