import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  InjectionToken,
  isDevMode,
} from '@angular/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAuth0 } from '@auth0/auth0-angular';
import { ApiModule, Configuration, ConfigurationParameters } from '@cloud-api';
import {
  appInitializerProviders,
  BASE_URL,
  httpInterceptorProviders,
} from '@core';
import { environment } from '@env/environment';
import { provideMomentDatetimeAdapter } from '@ng-matero/extensions-moment-adapter';
import { EffectsModule } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PaginatorI18nService } from '@shared';
import * as fromProjects from '@shared/services/project-store/project-store.reducer';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { ToastrModule } from 'ngx-toastr';
import { routes } from './app.routes';
import { AuthStatusService } from './core/authentication/auth-status.service';
import { PermissionService } from './core/authentication/permission.service';
import { AppMenuService } from './core/services/app-menu.service';
import { FormlyConfigModule } from './formly-config.module';
import { CustomSerializer } from './shared/services/custom-serializer';
import { ProjectStoreEffects } from './shared/services/project-store/project-store.effects';

// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'i18n/', '.json');
}

function initializeAuthStatus(authStatusService: AuthStatusService) {
  return () => new Promise<void>((resolve) => {
    // The service constructor will set up the subscription
    // We just need to wait a bit to ensure it's had time to initialize
    setTimeout(() => resolve(), 100);
  });
}

function initializePermissionsService(permissionsService: PermissionService) {
  return () => new Promise<void>((resolve) => {
    // The service constructor will set up the subscription
    // We just need to wait a bit to ensure it's had time to initialize
    setTimeout(() => resolve(), 100);
  });
}

function initializeAppMenu(appMenuService: AppMenuService) {
  return () => new Promise<void>((resolve) => {
    // The service constructor will set up the subscription
    // We just need to wait a bit to ensure it's had time to initialize
    setTimeout(() => resolve(), 100);
  });
}

export const BASE_API_URL = new InjectionToken<string>('BASE_API_URL');

const ApiConfigFactory = () => {
  const configParams: ConfigurationParameters = {
    basePath: environment.apiUrl,
  };
  return new Configuration(configParams);
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAuth0({
      ...environment.auth,
      httpInterceptor: { ...environment.httpInterceptor },
    }),
    AuthStatusService,
    {
      provide: APP_INITIALIZER,
      useFactory: (authStatusService: AuthStatusService) => initializeAuthStatus(authStatusService),
      deps: [AuthStatusService],
      multi: true
    },
    PermissionService,
    {
      provide: APP_INITIALIZER,
      useFactory: (permissionsService: PermissionService) => initializePermissionsService(permissionsService),
      deps: [PermissionService],
      multi: true
    },
    AppMenuService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appMenuService: AppMenuService) => initializeAppMenu(appMenuService),
      deps: [AppMenuService],
      multi: true
    },
    ApiModule.forRoot(ApiConfigFactory).providers,
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withComponentInputBinding(),
    ),
    importProvidersFrom(
      NgProgressHttpModule,
      NgProgressRouterModule,
      NgxPermissionsModule.forRoot(),
      StoreModule.forRoot({
        [fromProjects.projectFeatureKey]: fromProjects.reducer,
        // Add other reducers here
      }),
      EffectsModule.forRoot([ProjectStoreEffects]),
      StoreRouterConnectingModule.forRoot({
        serializer: FullRouterStateSerializer,
      }),
      ToastrModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: TranslateHttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      FormlyConfigModule.forRoot(),
    ),
    { provide: BASE_URL, useValue: environment.baseUrl },
    { provide: BASE_API_URL, useValue: environment.apiUrl },
    // ==================================================
    // 👇 ❌ Remove it in the realworld application
    //
    // { provide: LoginService, useClass: FakeLoginService },
    //
    // ==================================================
    httpInterceptorProviders,
    appInitializerProviders,
    {
      provide: MatPaginatorIntl,
      deps: [PaginatorI18nService],
      useFactory: (paginatorI18nSrv: PaginatorI18nService) =>
        paginatorI18nSrv.getPaginatorIntl(),
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: () => navigator.language, // <= This will be overrided by runtime setting
    },
    {
      provide: MAT_CARD_CONFIG,
      useValue: {
        appearance: 'outlined',
      },
    },
    provideMomentDateAdapter({
      parse: {
        dateInput: 'YYYY-MM-DD',
      },
      display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'YYYY MMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY MMM',
      },
    }),
    provideMomentDatetimeAdapter({
      parse: {
        dateInput: 'YYYY-MM-DD',
        yearInput: 'YYYY',
        monthInput: 'MMMM',
        datetimeInput: 'YYYY-MM-DD HH:mm',
        timeInput: 'HH:mm',
      },
      display: {
        dateInput: 'YYYY-MM-DD',
        yearInput: 'YYYY',
        monthInput: 'MMMM',
        datetimeInput: 'YYYY-MM-DD HH:mm',
        timeInput: 'HH:mm',
        monthYearLabel: 'YYYY MMMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        popupHeaderDateLabel: 'MMM DD, ddd',
      },
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    { provide: RouterStateSerializer, useClass: CustomSerializer },
  ],
};
