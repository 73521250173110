import { computed, inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { getProject } from '@app/shared/services/project-store/project-store.selectors';
import { select, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class RouteTitleService {
  private readonly _store = inject(Store);
  
  private currentProjectSignal = toSignal(this._store.pipe(select(getProject)));

  public pageTitle: Signal<string> = computed(() => {
    const project = this.currentProjectSignal();

    let title = '';
    
    if (project) {
      title = `${project.name}`;
    }
    
    return title;
  });
}