import { computed, DestroyRef, effect, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@auth0/auth0-angular';
import { UsersService } from '@cloud-api';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

function foo<T extends string>(prop: T, val: string[]): Record<T, string[]> {
  return {
      [prop]: val
  } as Record<T, string[]>;
}

@Injectable({
  providedIn: 'root'
})
export class AuthStatusService {
  private readonly _auth = inject(AuthService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _permissionService = inject(NgxPermissionsService);
  private readonly _rolesService = inject(NgxRolesService);
  private readonly _usersService = inject(UsersService);

  private isAuthenticatedSignal = signal<boolean>(false);

  public isAuthenticated = computed(() => this.isAuthenticatedSignal());

  constructor() {
    this._auth.isAuthenticated$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((isAuthenticated) => {
        this.isAuthenticatedSignal.set(isAuthenticated);
      });

    effect(() => {
      console.log('Auth status changed:', this.isAuthenticated());
    });
  }
}