import { DestroyRef, effect, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { getProject } from '@app/shared/services/project-store/project-store.selectors';
import { AuthService } from '@auth0/auth0-angular';
import { ProjectUserRole, UsersService } from '@cloud-api';
import { select, Store } from '@ngrx/store';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { map } from 'rxjs';
import { AuthStatusService } from './auth-status.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private readonly _auth = inject(AuthService);
  private readonly _authStatus = inject(AuthStatusService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _permissionService = inject(NgxPermissionsService);
  private readonly _rolesService = inject(NgxRolesService);
  private readonly _usersService = inject(UsersService);
  private readonly _store = inject(Store);

  private authIdSignal = toSignal(
    this._auth.user$.pipe(map((user) => user?.sub)),
  );

  private currentProjectSignal = toSignal(this._store.pipe(select(getProject)));

  constructor() {
    effect(
      () => {
        this.updatePermissions();
      },
      { allowSignalWrites: true },
    );
  }

  private updatePermissions() {
    this._permissionService.flushPermissions();
    this._rolesService.flushRoles();

    if (this._authStatus.isAuthenticated()) {
      const project = this.currentProjectSignal();
      const authId = this.authIdSignal();

      const rolesObj = {};

      this._usersService.getUsersRoles().subscribe((roles) => {
        roles.forEach((role) => {
          rolesObj[role.toUpperCase()] = [role.toLowerCase()];
        });

        if (project) {
          let projectRole = ProjectUserRole.ReadOnly;
          if (rolesObj['ADMIN'] != null && rolesObj['ADMIN'] != undefined) {
            projectRole = ProjectUserRole.Admin;
          } else {
            projectRole = project?.projectAppUsers?.find(
              (projectUser) => projectUser.id == authId,
            )?.role;
          }

          if (projectRole)
            rolesObj['PROJECT_' + projectRole.toUpperCase()] = ['project_' + projectRole.toLowerCase()];
        }

        this._rolesService.addRolesWithPermissions(rolesObj);
      });
    }
  }
}
