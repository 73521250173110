import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AdminLayoutComponent } from '@theme/admin-layout/admin-layout.component';
import { FrontpageComponent } from './routes/frontpage/frontpage.component';

export const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: FrontpageComponent
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('./routes/admin/admin.routes').then((m) => m.routes),
      },
      {
        path: 'p',
        loadChildren: () => import('./routes/project/project.routes').then((m) => m.routes),
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('./routes/profile/profile.routes').then((m) => m.routes),
      },
    ],
  },
  { path: '**', redirectTo: 'dashboard' },
];
