import { computed, effect, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Menu, MenuChildrenItem, MenuService } from '@app/core';
import { getProject } from '@app/shared/services/project-store/project-store.selectors';
import { Project } from '@cloud-api';
import { select, Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppMenuService {
  private readonly _permissionService = inject(NgxPermissionsService);
  private readonly _menuService = inject(MenuService);
  private readonly _store = inject(Store);

  private currentProjectSignal = toSignal(this._store.pipe(select(getProject)));
  private permissionsSignal = toSignal(
    this._permissionService.permissions$.pipe(
      map((permissions) => Object.keys(permissions)),
    ),
  );

  public menu = computed(() => this.buildMenu());

  constructor() {
    // Log menu changes
    effect(() => {
      this._menuService.set(this.menu());
    });
  }

  private buildMenu(): Menu[] {
    const project = this.currentProjectSignal();
    const permissions = this.permissionsSignal();

    let menu: Menu[] = [];

    if (
      project &&
      (permissions.includes('project_admin') ||
        permissions.includes('project_manager') ||
        permissions.includes('project_user') ||
        permissions.includes('project_read_only'))
    ) {
      menu = this.buildProjectMenu(project, permissions);
    }

    if (permissions.includes('admin')) {
      menu.push(this.buildAdminMenu());
    }

    return menu;
  }

  private buildProjectMenu(project: Project, roles: string[]): Menu[] {
    const workloads = this.buildWorkloadsMenu(project);
    const studies = this.buildStudiesMenu(project);

    let menu: Menu[] = [
      {
        route: `p/${project.id}/dashboard`,
        name: 'menu.dashboard',
        type: 'link',
        icon: 'dashboard',
      },
    ];

    if (roles.includes('project_admin') || roles.includes('project_manager')) {
      menu.push({
        route: `p/${project.id}/workloads`,
        name: 'menu.workloads',
        type: 'sub',
        icon: 'build',
        children: workloads,
      });
    }

    menu.push({
      route: `p/${project.id}/studies`,
      name: 'menu.studies',
      type: 'sub',
      icon: 'folder_open',
      children: studies,
    });

    if (roles.includes('project_admin')) {
      menu.push({
        route: `p/${project.id}/settings`,
        name: 'menu.project.settings',
        type: 'sub',
        icon: 'tune',
        children: [
          {
            name: 'menu.users',
            type: 'link',
            route: '/users',
          },
          {
            name: 'menu.options',
            type: 'link',
            route: '/options',
          },
        ],
      });
    }

    return menu;
  }

  private buildWorkloadsMenu(project: Project): MenuChildrenItem[] {
    const workloads =
      project.workloads?.map(
        (workload) =>
          ({
            name: workload.name,
            type: 'link',
            route: `/${workload.id}`,
          }) as MenuChildrenItem,
      ) || [];

    workloads.push({
      name: 'menu.add-workload',
      type: 'link',
      route: '/add',
    });

    return workloads;
  }

  private buildStudiesMenu(project: Project): MenuChildrenItem[] {
    const studies =
      project.studies?.map(
        (study) =>
          ({
            name: study.name,
            type: 'link',
            route: `/${study.id}`,
          }) as MenuChildrenItem,
      ) || [];

    studies.push({
      name: 'menu.add-study',
      type: 'link',
      route: '/add',
    });

    return studies;
  }

  private buildAdminMenu(): Menu {
    return {
      name: 'menu.admin',
      type: 'sub',
      route: 'admin',
      icon: 'settings',
      children: [
        {
          route: 'users',
          name: 'menu.users',
          type: 'link',
        },
        {
          route: 'projects',
          name: 'menu.projects',
          type: 'link',
        },
        {
          name: 'menu.container-monitor',
          type: 'link',
          route: '/cmonitor',
        },
        {
          name: 'menu.container-logs',
          type: 'link',
          route: '/clogs',
        },
      ],
    };
  }
}
